<script setup>
import { Link, useForm } from '@inertiajs/vue3';
import { inject } from 'vue';

import AddressForm from '@/Components/Address/AddInForm.vue';
import Button from '@/Components/Button/Button.vue';
import FormSingleSettingBar from '@/Components/Form/FormSingleSettingBar.vue';
import FormSubject from '@/Components/Form/FormSubject.vue';
import FormSecurity from './FormSecurity.vue';
// Icons
import { mapErrors } from '@/utilities';
import saveIcon from '@i/save-white.svg';

const dayjs = inject('dayjs');
const route = inject('route');

const props = defineProps({
    flexer: Object,
    maritalStatusOptions: Object,
    identificationCardTypeOptions: Object,
    countryOptions: Object,
    otherFlexers: Object,
});

const form = useForm({
    marital_status: Object.keys(props.maritalStatusOptions)[0],
    ...props.flexer,
    brought_in_by_id: null,
    date_of_birth: dayjs(props.flexer.date_of_birth).format('YYYY-MM-DD'),
    identification_card_valid_until: props.flexer.identification_card_valid_until
        ? dayjs(props.flexer.identification_card_valid_until).format('YYYY-MM-DD')
        : null,
    street_name: props.flexer.street_name,
    street_number: props.flexer.street_number,
    street_number_addition: props.flexer.street_number_addition,
    postcode: props.flexer.postcode,
    city: props.flexer.city,
    country: props.flexer.country,
    latitude: props.flexer.latitude,
    longitude: props.flexer.longitude,
    iban: props.flexer.iban,
    identification_card_type: props.flexer.identification_card_type,
    identification_card_number: props.flexer.identification_card_number,
    apply_payroll_tax_credit: props.flexer.apply_payroll_tax_credit ?? false,
    tax_country: props.flexer.tax_country,
    birth_city: props.flexer.birth_city,
    birth_country: props.flexer.birth_country,
});

const handleAddressInputChanged = (input, value) => {
    if (typeof input === 'object') {
        Object.keys(input).forEach((key) => {
            form[key] = input[key];
        });
        return;
    }

    form[input] = value;
};

const sendForm = () => {
    form.transform((data) => {
        console.log(data);
        if (data.brought_in_by_id === '') {
            delete data.brought_in_by_id;
        }

        return data;
    }).put(route('staff.flexers.update', { id: props.flexer.id }), {
        preserveState: true,
        preserveScroll: true,
        onError: (errors) => (form.errors = mapErrors(errors)),
    });
};
</script>
<template>
    <FormKit type="form" @submit="sendForm" :actions="false">
        <div class="grid mt-8 gap-y-8">
            <div class="grid gap-x-8 gap-y-2 md:grid-cols-2">
                <FormKit
                    type="text"
                    name="first_name"
                    :label="$t('First name')"
                    v-model="form.first_name"
                    :errors="form.errors.first_name"
                    :value="form.first_name"
                />
                <FormKit
                    type="text"
                    name="last_name"
                    :label="$t('Last name')"
                    v-model="form.last_name"
                    :errors="form.errors.last_name"
                    :value="form.last_name"
                />
                <FormKit
                    type="text"
                    name="nickname"
                    :label="$t('Calling name')"
                    v-model="form.nickname"
                    :errors="form.errors.nickname"
                    :value="form.nickname"
                />
                <FormKit
                    type="text"
                    name="email"
                    :label="$t('E-mail')"
                    v-model="form.email"
                    :errors="form.errors.email"
                    :value="form.email"
                />
                <FormKit
                    type="text"
                    name="phone_number"
                    :label="$t('Mobile number')"
                    v-model="form.phone_number"
                    :errors="form.errors.phone_number"
                    :value="form.phone_number"
                />
                <FormKit
                    type="date"
                    name="date_of_birth"
                    :label="$t('Date of birth')"
                    v-model="form.date_of_birth"
                    :errors="form.errors.date_of_birth"
                    :value="form.date_of_birth"
                />

                <FormKit
                    type="select"
                    select-icon="down"
                    name="gender"
                    :label="$t('Gender')"
                    v-model="form.gender"
                    :errors="form.errors.gender"
                    :value="form.gender"
                    :placeholder="$t('Select an option')"
                    :options="{
                        male: $t('Male'),
                        female: $t('Female'),
                    }"
                />

                <div>
                    <FormKit
                        v-if="flexer.brought_in_by"
                        type="text"
                        :value="flexer.brought_in_by?.display_name ?? 'Unknown'"
                        :label="$t('Brought in by')"
                        readonly
                        input-class="opacity-50"
                        :help="$t('This value cannot be changed.')"
                    />

                    <FormKit
                        v-else
                        type="select"
                        select-icon="down"
                        name="brought_in_by_id"
                        :label="$t('Brought in by')"
                        v-model="form.brought_in_by_id"
                        :errors="form.errors.brought_in_by_id"
                        :value="form.brought_in_by_id"
                        :placeholder="$t('Select an option')"
                        :options="{
                            '': $t('Select an option'),
                            ...otherFlexers,
                        }"
                    />
                </div>

                <FormKit
                    type="checkbox"
                    name="speaks_dutch"
                    :label="$t('Speaks ...')"
                    v-model="form.native_tongue"
                    :errors="form.errors.native_tongue"
                    :value="form.native_tongue"
                    :options="{
                        nl: $t('Dutch'),
                        en: $t('English'),
                    }"
                />

                <FormKit
                    type="select"
                    select-icon="down"
                    name="marital_status"
                    :label="$t('Marital status')"
                    v-model="form.marital_status"
                    :errors="form.errors.marital_status"
                    :value="form.marital_status"
                    :options="{ '': $t('Select an option'), ...maritalStatusOptions }"
                />

                <FormKit
                    type="checkbox"
                    name="has_email_notification"
                    :label="$t('Email notifications')"
                    v-model="form.has_email_notification"
                    :errors="form.errors.has_email_notification"
                    :value="form.has_email_notification"
                />

                <FormKit
                    type="checkbox"
                    name="has_phone_notification"
                    :label="$t('Phone Notification')"
                    v-model="form.has_phone_notification"
                    :errors="form.errors.has_phone_notification"
                    :value="form.has_phone_notification"
                />

                <FormKit
                    type="checkbox"
                    name="has_drivers_license"
                    :label="$t('Drivers license')"
                    v-model="form.has_drivers_license"
                    :errors="form.errors.has_drivers_license"
                    :value="form.has_drivers_license"
                />
            </div>
            <FormSubject v-if="form.type == 'temp_worker'" :title="$t('Payment details')">
                <div class="grid gap-x-8 gap-y-2 #md:grid-cols-2">
                    <FormKit
                        type="text"
                        name="iban"
                        :label="$t('IBAN')"
                        v-model="form.iban"
                        :errors="form.errors.iban"
                        :value="form.iban"
                    />
                    <FormKit
                        type="text"
                        name="bic"
                        :label="$t('BIC')"
                        v-model="form.bic"
                        :errors="form.errors.bic"
                        :value="form.bic"
                    />
                    <FormKit
                        type="select"
                        select-icon="down"
                        name="tax_country"
                        :label="$t('Tax country')"
                        v-model="form.tax_country"
                        :errors="form.errors.tax_country"
                        :value="form.tax_country"
                        :options="{ '': $t('Select an option'), ...countryOptions }"
                    />
                    <FormKit
                        type="checkbox"
                        name="apply_payroll_tax_credit"
                        :label="$t('Apply payroll tax credit')"
                        v-model="form.apply_payroll_tax_credit"
                        :errors="form.errors.apply_payroll_tax_credit"
                        :value="form.apply_payroll_tax_credit"
                    />
                </div>
            </FormSubject>
            <FormSubject v-if="form.type == 'temp_worker'" :title="$t('Easyflex')">
                <div class="grid gap-x-8 gap-y-2 md:grid-cols-2">
                    <FormKit
                        type="text"
                        name="easyflex_id"
                        :label="$t('Easyflex id')"
                        v-model="form.easyflex_id"
                        :errors="form.errors.easyflex_id"
                        :value="form.easyflex_id"
                    />
                </div>
            </FormSubject>

            <FormSubject :title="$t('Identification')">
                <div class="grid gap-x-8 gap-y-2 md:grid-cols-2">
                    <FormKit
                        type="text"
                        name="bsn"
                        :label="$t('BSN')"
                        v-model="form.bsn"
                        :errors="form.errors.bsn"
                        :value="form.bsn"
                    />

                    <FormKit
                        type="select"
                        select-icon="down"
                        name="identification_card_type"
                        :label="$t('Identification type')"
                        v-model="form.identification_card_type"
                        :errors="form.errors.identification_card_type"
                        :value="form.identification_card_type"
                        :options="{ '': $t('Select an option'), ...identificationCardTypeOptions }"
                    />

                    <FormKit
                        type="text"
                        name="identification_card_number"
                        :label="$t('Identification number')"
                        v-model="form.identification_card_number"
                        :errors="form.errors.identification_card_number"
                        :value="form.identification_card_number"
                    />

                    <FormKit
                        type="date"
                        name="identification_card_valid_until"
                        :label="$t('Identification number valid until')"
                        v-model="form.identification_card_valid_until"
                        :errors="form.errors.identification_card_valid_until"
                        :value="form.identification_card_valid_until"
                    />
                    <FormKit
                        type="text"
                        name="birth_city"
                        :label="$t('Birth city')"
                        v-model="form.birth_city"
                        :errors="form.errors.birth_city"
                        :value="form.birth_city"
                    />

                    <FormKit
                        type="select"
                        select-icon="down"
                        name="birth_country"
                        :label="$t('Birth country')"
                        v-model="form.birth_country"
                        :errors="form.errors.birth_country"
                        :value="form.birth_country"
                        :options="{ '': $t('Select an option'), ...countryOptions }"
                    />

                    <FormKit
                        type="select"
                        select-icon="down"
                        name="nationality"
                        :label="$t('Nationality')"
                        v-model="form.nationality"
                        :errors="form.errors.nationality"
                        :value="form.nationality"
                        :options="{ '': $t('Select an option'), ...countryOptions }"
                    />
                </div>
            </FormSubject>

            <FormSubject v-if="form.type === 'temp_worker'" :title="$t('Address')">
                <AddressForm :parent-form="form" @address-input-changed="handleAddressInputChanged" />
            </FormSubject>

            <FormSecurity :flexer="flexer" />

            <FormSingleSettingBar v-if="flexer.companies.length > 0" :title="$t('Companies')" removeInnerPadding>
                <div
                    class="grid grid-cols-3 border-b text-blue border-blue/30 min-h-13"
                    v-for="company in flexer.companies"
                    :key="company.id"
                >
                    <div class="self-center font-bold">{{ company.commercial_name }}</div>
                    <div class="self-center"></div>

                    <Link :href="route('staff.companies.edit', company.id)" class="self-center justify-self-end">
                        <img
                            class="float-right w-2 h-auto"
                            src="@/../img/icon/pagination-arrow.svg"
                            :alt="$t('Link')"
                        />
                    </Link>
                </div>
            </FormSingleSettingBar>
        </div>
        <div class="flex justify-end mt-8">
            <Button
                :processing="form.processing"
                :recentlySuccessful="form.recentlySuccessful"
                :text="$t('Save')"
                :icon="saveIcon"
                @click="sendForm"
            />
        </div>
    </FormKit>
</template>
